import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'

/**
 * 积分活动列表
 * @param {*} "pageNo" 页码
 * @param {*} "pageSize" 页数
 * @param {*} "keyWords" 
 * @returns
 */
export const integraActivitylList = ({ ...params }) => {
  let { pageNo, pageSize, ...param } = params
  return awaitWrap(
    request({
      url: `/integral/activity/list/${pageNo}/${pageSize}`,
      method: 'get',
      params: param,
      isLoading: true
    })
  )
}

/**
 * 新增or修改积分活动
 * @returns
 */
export const integralActivityUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/integral/activity/update`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 修改活动时间
 * @param {*} "activityId" 
 * @param {*} "endTime" 
 * @param {*} "startTime" 
 * @returns
 */
export const integralActivityUpdateTime = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/integral/activity/time`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 删除积分活动
 * @param {*} "activityId" 
 * @returns
 */
export const integralActivityDelete = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/integral/activity/delete`,
      method: 'post',
      data,
      isLoading: true
    })
  )
}

/**
 * 获取积分活动生成的二维码的下载地址
 * @param {*} "activityId" 
 * @returns
 */
export const integraActivitylDownloadUrl= ({ ...params }) => {
  return awaitWrap(
    request({
      url: `/integral/activity/downloadUrl`,
      method: 'get',
      params: params,
      isLoading: true
    })
  )
}

/**
 * 获取积分设置页面信息
 * @returns
 */
export const integralList = () => {
  return awaitWrap(
    request({
      url: `/sys/info/get`,
      method: 'GET'
    })
  )
}
/**
 * 添加、修改积分
 * @param {*} "bannerId" 轮播图id
 * @param {*} "imgUrl" 图片链接
 * @param {*} "name"  图片名称
 * @param {*} "redirectUrl" 轮播图跳转链接
 * @returns
 */
export const integralUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/sys/info/set`,
      method: 'POST',
      data
    })
  )
}
/**
 * 获取积分订单列表
 * @returns
 */
export const integralorderList = ({ ...params }) => {
  let { pageNo, pageSize, ...param } = params
  return awaitWrap(
    request({
      url: `/orders/list/${pageNo}/${pageSize}`,
      method: 'get',
      params: param
    })
  )
}
/**
 * 添加、修改积分
 * @param {*} "orderId" 订单id
 * @param {*} "process" 订单状态
 * @returns
 */
export const integralorderUpdate = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/orders/review`,
      method: 'POST',
      data
    })
  )
}
