<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="15" :md="8" :lg="6" :xl="4">
          <el-input v-model="params.name" @change="changeParams" clearable placeholder="请输入用户ID/昵称/姓名/手机号"> </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="10">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
          <el-button type="primary" class="margin-left20" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="userId" label="用户ID" align="center"></el-table-column>
      <el-table-column label="用户头像" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.img" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="用户昵称" align="center"></el-table-column>
      <el-table-column prop="trueName" label="姓名" align="center"></el-table-column>
      <el-table-column prop="trueName" label="微信授权" align="center"></el-table-column>
      <el-table-column prop="trueName" label="手机号" align="center"></el-table-column>
      <el-table-column prop="integral" label="代理级别" align="center"></el-table-column>
      <el-table-column prop="createTime" label="售货机" align="center">
        <template slot-scope="scope">
          64/100
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" max-width="180" align="center">
        <template slot-scope="scope">
          <div>
            <el-link style="margin:0 10px" type="success" :underline="false" @click="proagree(scope.row)">同意</el-link>
            <el-link style="margin:0 10px" type="danger" :underline="false" @click="prorefuse(scope.row)">拒绝</el-link>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import testImg from '@/assets/images/test.jpg'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { userList } from '@/api/UserController'
import { integralorderList, integralorderUpdate } from '../../../api/IntegralController.js'

export default {
  data() {
    return {
      tableList: [],
      params: {
        id: '',
        name: '',
        type: '',
        startTime: '',
        endTime: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    proagree(row) {
      this.$confirm(`确定要审核同意吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.orderId = row.ordersId
          ss.process = 2
          this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },
    prorefuse(row) {
      this.$confirm(`确定要拒绝吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let { ...ss } = {}
          ss.orderId = row.ordersId
          ss.process = 1
          this.integralorderUpdateReq(ss)
        })
        .catch(() => {})
    },
    async integralorderUpdateReq(data) {
      let [err, res] = await integralorderUpdate(data)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      } else {
        this.$message.success('操作成功')
        this.getintegralorderListReq()
      }
    },
    changeParams(v) {
      !v && this.handleSearch()
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },

    handleSearch() {
      console.log(this.params)
      this.getUserList()
    },

    async getUserList() {
      let [err, res] = await userList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.getUserList()
    }
  },
  components: {
    TitleBarBaseVue
  }
}
</script>
